// @flow

import * as React from 'react'
import colors from 'superdays-shared/colors'
import {Link} from 'gatsby'
import {fonts, fontStyles, toRem} from './typo'

type SharedButtonProps = {|
  label?: string,
  children?: React.Node,
  disabled?: boolean,
  style?: Object,
  className?: string,
  css?: Object | any[],
|}

// Forcing those types to be exact via {||}, we can assign different props
// to the link and the button variant
type BBAsButtonProps = {|
  onClick?: (SyntheticEvent<HTMLButtonElement>) => void | Promise<any>,
  type?: 'button' | 'submit',

  ...SharedButtonProps,
|}
type BBAsLinkProps = {|
  to: string,
  target?: '_blank',

  ...SharedButtonProps,
|}

type BBProps = BBAsButtonProps | BBAsLinkProps
type BBState = {
  error: null | string,
}

export class BaseButton extends React.Component<BBProps, BBState> {
  state = {error: null}

  handleClick = (clickHandler?: (e: SyntheticEvent<HTMLButtonElement>) => void | Promise<any>) => (
    e: SyntheticEvent<HTMLButtonElement>,
  ) => {
    if (this.state.error) this.setState({error: null})
    if (!clickHandler) return
    const result = clickHandler(e)
    if (result && result.then) {
      return result.catch((e: Error) => {
        this.setState({error: e.message || e.toString()})
        return Promise.reject(e)
      })
    } else {
      return result
    }
  }

  // $FlowFixMe
  render() {
    const {className, style, disabled, label, children, ...rest} = this.props

    const sharedProps = {children: children || label, style, className}
    if (rest.to !== undefined) {
      if (rest.to) {
        const {target, to, ...remaining} = rest
        const linkProps = {
          rel: target ? 'noopener noreferrer' : undefined,
        }
        if (to.startsWith('http') || to.startsWith('mailto:')) {
          // eslint-disable-next-line jsx-a11y/anchor-has-content
          return <a href={to} target={target} {...sharedProps} {...linkProps} {...remaining} />
        } else {
          return <Link to={to} target={target} {...sharedProps} {...linkProps} {...remaining} />
        }
      }
    } else {
      const {onClick, type = 'button', ...remaining} = rest
      const {error} = this.state
      return (
        <React.Fragment>
          <button
            type={type}
            disabled={disabled}
            onClick={disabled ? undefined : this.handleClick(onClick)}
            {...sharedProps}
            {...remaining}
          />
          {/* TODO: Style Error properly */}
          {error ? <div css={{color: colors.warning}}>{error}</div> : null}
        </React.Fragment>
      )
    }
  }
}

const styleByMode = {
  primary: {
    backgroundColor: colors.mintGreen,
    ':hover': {backgroundColor: colors.mintGreenHightlight},
  },
  blue: {
    backgroundColor: colors.periwinkle,
    ':hover': {backgroundColor: colors.periwinkleHighlight},
  },
}

export const sharedButtonStyle = {
  display: 'block',
  textDecoration: 'none',
  flexShrink: 0,
  textAlign: 'center',
}

const fullButtonStyle = ({disabled, mode}: {|...FBProps, ...BBProps|}) => [
  {
    ...sharedButtonStyle,
    ...fonts.heading,
    fontSize: toRem(14),
    lineHeight: 19 / 14,
    padding: '0.4rem 1rem 0.3rem',
    borderRadius: '2rem',
    color: colors.lightText,
    border: 'none',
    cursor: disabled ? 'inherit' : 'pointer',
    boxShadow: '0 2px 2px rgba(0,0,0,0.12)',
    transitionProperty: 'background-color',
  },
  styleByMode[mode || 'primary'],
  disabled && {
    backgroundColor: colors.gray,
    ':hover': {backgroundColor: colors.gray},
  },
]

type FBProps = {|
  mode?: $Keys<typeof styleByMode>,
|}
export class FullButton extends React.Component<{|...FBProps, ...BBProps|}> {
  render() {
    const {mode: _, ...rest} = this.props
    return <BaseButton css={fullButtonStyle(this.props)} {...rest} />
  }
}

const transparentButtonStyle = ({disabled}) => [
  {
    ...sharedButtonStyle,
    padding: '0.3rem',
    borderRadius: '0.3rem',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: disabled ? 'inherit' : 'pointer',
    transitionProperty: 'background-color',
    ':hover': {
      backgroundColor: 'rgba(0,0,0,0.05)',
    },
  },
  disabled && {opacity: 0.5},
]

class TransparentButton extends React.Component<BBProps> {
  render() {
    return <BaseButton css={transparentButtonStyle(this.props)} {...this.props} />
  }
}

type IconButtonProps = {|
  icon: {src: string, width: number, height: number},
|}

export class IconButton extends React.Component<{|...IconButtonProps, ...BBProps|}> {
  render() {
    const {
      icon: {src, width, height},
      ...rest
    } = this.props
    return (
      <TransparentButton {...rest}>
        <img src={src} width={width} height={height} css={{display: 'block', height: '1.5rem'}} />
      </TransparentButton>
    )
  }
}

const linkButtonStyle = ({disabled, inline}) => [
  {
    ...sharedButtonStyle,
    ...fonts.bodyBold,
    display: inline ? inline : 'block',
    color: disabled ? colors.text : colors.mintGreen,
    border: 'none',
    opacity: disabled ? 0.35 : 1,
    cursor: disabled ? 'inherit' : 'pointer',
    transitionProperty: 'background-color',
    borderRadius: '0.3rem',
    backgroundColor: 'transparent',
    ':hover': {
      backgroundColor: 'rgba(0,0,0,0.05)',
    },
  },
  inline
    ? {
        display: 'inline',
        fontSize: 'inherit',
        fontWeight: 'bold',
        fontFamily: 'inherit',
      }
    : {
        display: 'block',
        ...fontStyles.smallBoldBody,
      },
]

type LinkButtonProps = {|
  inline?: boolean,
|}

export class LinkButton extends React.Component<{|...LinkButtonProps, ...BBProps|}> {
  render() {
    const {inline: _, ...rest} = this.props
    return <BaseButton css={linkButtonStyle(this.props)} {...rest} />
  }
}
