// @flow
import React from 'react'
import {StripWithContent, Row, Push} from '../ui/layout'
import typo, {ExternalOrInternalLink, fontStyles} from '../ui/typo'
import {breakPoints} from '../ui/breakpoints'
import {SocialLinks} from './SocialSharing'

const FooterLink = props => (
  <ExternalOrInternalLink css={[fontStyles.smallBoldBody, fontStyles.link()]} {...props} />
)

const Footer = () => (
  <StripWithContent>
    <Row
      spacing={1}
      paddingVertical={1}
      align="center"
      breakAt={breakPoints.small}
      justify="space-between"
    >
      <typo.Small>© 2019 by Superdays</typo.Small>
      <SocialLinks />
      <Push />
      <FooterLink href="/as-treat">Superdays for Yourself</FooterLink>
      <FooterLink
        href="https://www.iubenda.com/privacy-policy/80217704/full-legal"
        rel="noopener noreferrer"
        target="_blank"
      >
        Privacy Policy
      </FooterLink>
    </Row>
  </StripWithContent>
)

export default Footer
