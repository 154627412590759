// @flow
import * as React from 'react'
import {Location} from '@reach/router'
import qs from 'qs'

import {ShowToast} from './Toaster'
import {setUserAsKnown} from '../lib/is-known'
import {identify} from '../lib/analytics'

/**

Effects:

- "?toaster=msg" -> Show "msg" as toast
- "?user=email@example.com" -> identify user, and mark person as someone who knows superdays

*/

type BaseProps = {
  location: *,
  navigate: *,
  showToast: *,
}

class BaseUrlHandler extends React.Component<BaseProps> {
  componentDidMount() {
    const {location, showToast, navigate} = this.props
    const orgQuery = qs.parse(location.search, {ignoreQueryPrefix: true})
    let query = orgQuery
    let reload = false

    if (query.toaster) {
      showToast({message: query.toaster, durationInMs: 1500 + query.toaster.length * 50})
      query = {...query, toaster: undefined}
    }
    if (query.user) {
      identify(query.user)
      setUserAsKnown()
      query = {...query, user: undefined}

      // Needed since changing a cookie value here doesn't cause the page to rerender
      reload = true
    }
    if (query !== orgQuery) {
      const path = [location.pathname, qs.stringify(query)].filter(Boolean).join('?')
      navigate(path, {replace: true})
    }
    if (reload) {
      // `false` means no hard refresh
      window.location.reload(false)
    }
  }

  render() {
    return null
  }
}

const UrlHandler = () => (
  <Location>
    {({location, navigate}) => (
      <ShowToast>
        {show => <BaseUrlHandler showToast={show} location={location} navigate={navigate} />}
      </ShowToast>
    )}
  </Location>
)
export default UrlHandler
