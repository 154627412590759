// @flow
import * as React from 'react'
import {css} from 'glamor'
import {Location} from '@reach/router'
import Media from 'react-media'

import colors from 'superdays-shared/colors'

import {fontStyles, ExternalOrInternalLink} from '../ui/typo'
import {FullButton} from '../ui/buttons'
import {Row, StripWithContent, Push} from '../ui/layout'
import {breakPoints} from '../ui/breakpoints'
import IsScrolledDown from './IsScrolledDown'
import {SetScrollTarget} from './ScrollManager'
import {isUserKnown} from '../lib/is-known'
import LogoImage from '../images/logo__landing.svg'

const Logo = () => (
  <ExternalOrInternalLink href="/">
    <img src={LogoImage} />
  </ExternalOrInternalLink>
)

const ScrollLink = ({scrollTarget, children}) => (
  <SetScrollTarget>
    {scrollTo => (
      <div
        onClick={() => scrollTo(scrollTarget)}
        css={[fontStyles.bodyBold, fontStyles.link(colors.text, colors.grayText)]}
      >
        {children}
      </div>
    )}
  </SetScrollTarget>
)

const containerStyle = {
  position: 'fixed',
  left: 0,
  right: 0,
  top: 0,
  zIndex: 2,
  boxShadow: '0 0.1rem 0.5rem 0 transparent',
  transitionProperty: 'box-shadow',
}

const smallHeaderStyle = isScrolled =>
  css([
    {transitionProperty: 'padding'},
    isScrolled && {
      paddingTop: '1rem',
      paddingBottom: '1rem',
      [breakPoints.mini]: {
        paddingTop: '0.5rem',
        paddingBottom: '0.5rem',
      },
    },
  ])

type HCProps = {
  children: (boolean, boolean) => React.Node,
}
const HeaderContainer = ({children}: HCProps) => (
  <Location>
    {({location}) => {
      const isRootPage = location.pathname === '/'
      return (
        <IsScrolledDown threshold={20}>
          {isScrolled => (
            <div
              css={[
                containerStyle,
                isScrolled && {boxShadow: '0 0.1rem 0.5rem 0 rgba(0,0,0,0.05)'},
              ]}
            >
              <StripWithContent color="#FFF">
                <Row
                  spacing={1}
                  paddingVertical={2}
                  align="center"
                  innerClassName={smallHeaderStyle(isScrolled)}
                >
                  <Logo />
                  <Push />
                  <Row spacing={2} align="center">
                    {children(isRootPage, isScrolled)}
                  </Row>
                </Row>
              </StripWithContent>
            </div>
          )}
        </IsScrolledDown>
      )
    }}
  </Location>
)

const ScrollToRegisterButton = () => (
  <SetScrollTarget>
    {scrollTo => (
      <FullButton onClick={() => scrollTo('top-register-strip')} mode="primary">
        Join beta!
      </FullButton>
    )}
  </SetScrollTarget>
)

const RootPageAnchorButtons = () => (
  <Media query="(min-width: 700px)" defaultMatches={false}>
    {isLargeScreen =>
      isLargeScreen && (
        <React.Fragment>
          <ScrollLink scrollTarget="steps-strip">How it works</ScrollLink>
          <ScrollLink scrollTarget="features-strip">Features</ScrollLink>
          <ScrollLink scrollTarget="our-story">Our Story</ScrollLink>
        </React.Fragment>
      )
    }
  </Media>
)

const Header = () => (
  <HeaderContainer>
    {(isRootPage, isScrolled) =>
      isRootPage ? (
        <React.Fragment>
          <RootPageAnchorButtons />
          {isUserKnown() ? (
            <FullButton to="/download" mode="primary">
              Download
            </FullButton>
          ) : isScrolled ? (
            <ScrollToRegisterButton />
          ) : null}
        </React.Fragment>
      ) : (
        <ExternalOrInternalLink
          href="/"
          css={[fontStyles.bodyBold, fontStyles.link(colors.text, colors.grayText)]}
        >
          Go Back
        </ExternalOrInternalLink>
      )
    }
  </HeaderContainer>
)

export default Header
