// @flow
import * as React from 'react'
import colors from 'superdays-shared/colors'
import {BaseButton, sharedButtonStyle} from './buttons'
import {fontStyles, toRem} from './typo'
import {breakPoints} from './breakpoints'
import {ScrollTarget} from '../components/ScrollManager'

const stripStyle = {
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
}

const stripContentStyle = {
  width: '100%',
  marginLeft: 'auto',
  marginRight: 'auto',
  paddingLeft: '2rem',
  paddingRight: '2rem',
}

type SWCProps = {
  color?: string,
  backgroundGradient?: string,
  isDark?: boolean,
  maxWidth?: number,
  top?: React.Node,
  bottom?: React.Node,
  scrollKey?: string,
}
export const StripWithContent = ({
  color,
  backgroundGradient,
  isDark,
  maxWidth = 80,
  top,
  bottom,
  scrollKey,
  ...rest
}: SWCProps) => (
  // $FlowFixMe
  <ScrollTarget scrollKey={scrollKey} offset={50}>
    {registerNode => (
      <div
        ref={registerNode}
        css={[
          stripStyle,
          {backgroundColor: color},
          backgroundGradient && {background: backgroundGradient},
          isDark && {color: '#fff'},
        ]}
      >
        {top}
        <div
          css={[
            stripContentStyle,
            {maxWidth: `${maxWidth}rem`},
            (top || bottom) && {position: 'relative', zIndex: 1},
          ]}
          {...rest}
        />
        {bottom}
      </div>
    )}
  </ScrollTarget>
)

type Alignment = 'center' | 'stretch' | 'flex-end'
type Justification = 'center' | 'flex-start' | 'space-between'
type RSProps = {
  spacing: number,
  breakAt?: string,
  paddingTop: number,
  paddingBottom: number,
  paddingHorizontal: number,
  align: Alignment,
  justify: Justification,
  wrap: boolean,
}
const rowStyle = ({
  spacing,
  breakAt,
  paddingTop,
  paddingBottom,
  paddingHorizontal,
  align,
  justify,
  wrap,
}: RSProps) => [
  {
    display: 'flex',
    flexWrap: wrap ? 'wrap' : 'nowrap',
    alignItems: align,
    justifyContent: justify,

    paddingTop: `${paddingTop}rem`,
    paddingBottom: `${paddingBottom}rem`,
    paddingLeft: `${paddingHorizontal}rem`,
    paddingRight: `${paddingHorizontal}rem`,

    // We're using negative margin to ensure that the first and last children's spacing
    // don't add additional padding.
    marginLeft: `${-spacing / 2}rem`,
    marginRight: `${-spacing / 2}rem`,

    // This selector affects all direct children.
    '> *': {
      marginLeft: `${spacing / 2}rem`,
      marginRight: `${spacing / 2}rem`,
    },
  },
  // If a breakAt media query is passed, turn the Row into a Col.
  breakAt && {
    [breakAt]: {
      flexDirection: 'column',
      marginLeft: 0,
      marginRight: 0,
      marginTop: `${-spacing / 2}rem`,
      marginBottom: `${-spacing / 2}rem`,
      '> *': {
        marginLeft: 0,
        marginRight: 0,
        marginTop: `${spacing / 2}rem`,
        marginBottom: `${spacing / 2}rem`,
      },
    },
  },
]

type RowProps = {
  spacing?: number,
  padding?: number,
  paddingVertical?: number,
  paddingHorizontal?: number,
  paddingTop?: number,
  paddingBottom?: number,
  align?: Alignment,
  justify?: Justification,
  breakAt?: string,
  children?: React.Node,
  innerClassName?: string | {toString: () => string},
  wrap?: boolean,
}
export const Row = ({
  spacing = 0,
  padding = 0,
  paddingVertical = padding,
  paddingHorizontal = padding,
  paddingTop = paddingVertical,
  paddingBottom = paddingVertical,
  align = 'stretch',
  justify = 'flex-start',
  breakAt,
  children,
  wrap = false,
  /*
  TODO: this is mostly used for responsive fixes. Try to offer an api like this instead:
  <Row spacing={3} responsiveOverwrites={{[breakPoints.mini]}: {spacing: 1}}>
  */
  innerClassName,
  ...rest
}: RowProps) => (
  <div {...rest}>
    {/*
      Adding an extra div prevents margin conflicts when a Row is a child of another Row.
      This pretty much doubles the amount of divs in the dom. But other solutions fail at edge
      cases: e.g. padding=0 spacing>0, changing flex-order.

      This hack can be replaced by either using css grids or by waiting for `row-gap` to be live:
      https://github.com/w3c/csswg-drafts/issues/1696

      Potential issue: when trying to do <Row css={styles}/>, `styles` will only be applied to
      the outer div. So <Row css={{flex: 1}}/> would not do what you expect. Instead you'd need
      to explicitly support this via an extra prop like e.g. <Row grow/>.
    */}
    <div
      css={rowStyle({
        spacing,
        breakAt,
        paddingTop,
        paddingBottom,
        paddingHorizontal,
        align,
        justify,
        wrap,
      })}
      className={innerClassName}
    >
      {children}
    </div>
  </div>
)

type CSProps = {
  spacing: number,
  paddingTop: number,
  paddingBottom: number,
  paddingHorizontal: number,
  align: Alignment,
  grow?: boolean,
}
const colStyle = ({
  spacing,
  paddingTop,
  paddingBottom,
  paddingHorizontal,
  align,
  grow,
  ...rest
}: CSProps) => [
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: align,

    paddingTop: `${paddingTop}rem`,
    paddingBottom: `${paddingBottom}rem`,
    paddingLeft: `${paddingHorizontal}rem`,
    paddingRight: `${paddingHorizontal}rem`,
    marginTop: `${-spacing / 2}rem`,
    marginBottom: `${-spacing / 2}rem`,
    '> *': {
      marginTop: `${spacing / 2}rem`,
      marginBottom: `${spacing / 2}rem`,
    },
  },
  grow && {flex: 'auto'},
  rest,
]

type ColProps = {
  spacing?: number,
  padding?: number,
  paddingVertical?: number,
  paddingHorizontal?: number,
  paddingTop?: number,
  paddingBottom?: number,
  align?: Alignment,
  minHeight?: number | string,
  children?: React.Node,
  innerRef?: React.ElementRef<any>,
  grow?: boolean,
  innerClassName?: string | {toString: () => string},
}
export const Col = ({
  spacing = 0,
  padding = 0,
  paddingVertical = padding,
  paddingHorizontal = padding,
  paddingTop = paddingVertical,
  paddingBottom = paddingVertical,
  align = 'stretch',
  minHeight,
  children,
  innerRef,
  grow,
  innerClassName,
  ...rest
}: ColProps) => (
  <div ref={innerRef} css={[grow && {display: 'flex', flex: 'auto'}]} {...rest}>
    {/* Check comment above for why there are two divs here. */}
    <div
      css={colStyle({
        spacing,
        paddingTop,
        paddingBottom,
        paddingHorizontal,
        align,
        minHeight,
        grow,
      })}
      className={innerClassName}
    >
      {children}
    </div>
  </div>
)

type PushProps = {
  weight?: number,
}
export const Push = ({weight = 1}: PushProps) => (
  <div css={{flexGrow: weight, margin: '0 !important'}} />
)

const cardStyle = {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#fff',
  borderRadius: '0.3rem',
  boxShadow: '0px 20px 30px rgba(0, 0, 0, 0.08)',
}

type CardProps = {
  withBorder?: boolean,
  children: React.Node,
}
export const Card = ({withBorder, ...rest}: CardProps) => (
  <div css={[cardStyle, withBorder && {border: `1px solid ${colors.mintGreen}`}]} {...rest} />
)

const cardWithinButtonStyle = {
  ...sharedButtonStyle,
  ...fontStyles.h3,
  cursor: 'pointer',
  color: colors.lightText,
  border: 'none',
  borderRadius: '0 0 0.3rem 0.3rem',
  padding: '0.6rem 1rem 0.5rem',
  transitionProperty: 'background-color',
  boxShadow: '0 0 5px rgba(0,0,0,0.2)',
  backgroundColor: colors.mintGreen,
  ':hover': {backgroundColor: colors.mintGreenHightlight},
  [breakPoints.small]: {
    fontSize: toRem(16),
    padding: '0.4rem 1rem 0.3rem',
  },
}

type CardWithButtonProps = CardProps & {
  onClick?: (SyntheticEvent<HTMLButtonElement>) => void | Promise<any>,
  onCardClick?: (SyntheticEvent<HTMLDivElement>) => void | Promise<any>,
  to?: string,
  buttonLabel: string,
}

export const CardWithButton = ({
  children,
  buttonLabel,
  onClick,
  onCardClick,
  to,
  ...rest
}: CardWithButtonProps) => (
  <Card onClick={onCardClick} {...rest}>
    <Col grow css={{overflow: 'hidden'}}>
      {children}
      <Push />
      {to ? (
        <BaseButton css={cardWithinButtonStyle} to={to}>
          {buttonLabel}
        </BaseButton>
      ) : (
        <BaseButton css={cardWithinButtonStyle} onClick={onClick}>
          {buttonLabel}
        </BaseButton>
      )}
    </Col>
  </Card>
)
