// @flow
import * as React from 'react'
import MoveTo from 'moveto'

/*
Usage:

- Wrap app in the `ScrollProvider`

  <ScrollProvider>
    <App/>
  </ScrollProvider>

- Register your scroll target dom nodes

  <ScrollTarget scrollKey="myName">{registerNode => (
    <div>
      <h2 ref={registerNode}>My scroll target</h2>
    </div>
  )}</ScrollTarget>

- Jump to a scroll target

  <SetScrollTarget>{scrollTo => (
    <button onClick={() => scrollTo('myName')}>Scroll!</button>
  )}</SetScrollTarget>
*/

const mover = new MoveTo()

type SPState = {
  currentTarget: string | null,
  setTarget: (target: string | null) => void,
}
const ScrollContext = React.createContext<SPState>({
  currentTarget: null,
  setTarget: () => {
    // eslint-disable-next-line no-console
    console.warn('You need to use a `ScrollProvider` up the component tree!')
  },
})

type SPProps = {
  children: React.Node,
}
export class ScrollProvider extends React.Component<SPProps, SPState> {
  state = {
    currentTarget: null,
    setTarget: (target: string | null) => this.setState({currentTarget: target}),
  }

  render() {
    const {children} = this.props
    return <ScrollContext.Provider value={this.state}>{children}</ScrollContext.Provider>
  }
}

type STProps = {
  scrollKey: string,
  children: (handleRef: (n: any) => any) => React.Node,
  onScroll?: () => any,
  offset?: number,
}
class InnerScrollTarget extends React.Component<STProps & {scrollContext: SPState}> {
  node = null

  handleRef = node => {
    this.node = node
    if (this.node) this.checkIfScroll(this.node)
  }

  componentDidUpdate() {
    if (this.node) this.checkIfScroll(this.node)
    this.checkIfScroll()
  }

  checkIfScroll(node) {
    const {scrollKey, scrollContext, onScroll, offset} = this.props
    if (node && scrollKey === scrollContext.currentTarget) {
      mover.move(node, {callback: onScroll || (() => {}), tolerance: offset})
      scrollContext.setTarget(null)
    }
  }

  render() {
    const {children} = this.props
    return children(this.handleRef)
  }
}

export const ScrollTarget = (props: STProps) => (
  <ScrollContext.Consumer>
    {ctx => <InnerScrollTarget {...props} scrollContext={ctx} />}
  </ScrollContext.Consumer>
)

type SSTProps = {
  children: (handler: (target: string) => void) => React.Node,
}
export const SetScrollTarget = ({children}: SSTProps) => (
  <ScrollContext.Consumer>{ctx => children(ctx.setTarget)}</ScrollContext.Consumer>
)
