// @flow
export const breakPoints = {
  mobile: '@media(max-width: 375px)',
  notMobile: '@media(max-width: 375px)',

  mini: '@media(max-width: 500px)',
  notMini: '@media(min-width: 501px)',

  small: '@media(max-width: 700px)',
  notSmall: '@media(min-width: 701px)',

  medium: '@media(max-width: 900px)',
  notMedium: '@media(min-width: 901px)',
}
