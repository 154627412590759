// @flow
import * as React from 'react'

/*
For performance reasons we want to tell the browser that we're only intending to listen to scroll
events. We don't want to interrupt them. This is what the passive argument in event listeners is
there for.

Code inspired by
https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md#feature-detection
*/

let passiveArg = false
try {
  // $FlowFixMe
  var opts = Object.defineProperty({}, 'passive', {
    // eslint-disable-next-line getter-return
    get() {
      passiveArg = {passive: true}
    },
  })
  window.addEventListener('testPassive', null, opts)
  window.removeEventListener('testPassive', null, opts)
} catch (e) {
  // Swallow any errors.
}

type ISDProps = {
  children: boolean => React.Node,
  threshold?: number,
}
type ISDState = {
  isScrolled: boolean,
}
export default class IsScrolledDown extends React.Component<ISDProps, ISDState> {
  state = {isScrolled: this.checkIfScrolled()}

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, passiveArg)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, passiveArg)
  }

  handleScroll = () => {
    const isScrolled = this.checkIfScrolled()
    if (isScrolled !== this.state.isScrolled) {
      this.setState({isScrolled})
    }
  }

  checkIfScrolled() {
    const {threshold} = this.props
    if (typeof window === 'undefined') return false
    return window.scrollY > (threshold || 0)
  }

  render() {
    return this.props.children(this.state.isScrolled)
  }
}
