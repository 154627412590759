// @flow
import * as React from 'react'

import colors from 'superdays-shared/colors'

import {Col, StripWithContent} from '../ui/layout'
import typo from '../ui/typo'
import {BottomWave, BottomCurve} from '../ui/shapes'

type IntroProps = {
  bottomWave: 'none' | 'green' | 'yellow' | 'curve',
  headline: string,
  children: React.Node,
}
const Intro = ({bottomWave = 'none', headline, children}: IntroProps) => (
  <StripWithContent
    backgroundGradient={bottomWave === 'curve' ? colors.lightGreenGradient : ''}
    bottom={
      bottomWave !== 'none' ? (
        bottomWave === 'curve' ? (
          <BottomCurve />
        ) : (
          <BottomWave variant={bottomWave} />
        )
      ) : null
    }
  >
    <Col paddingVertical={3} paddingBottom={bottomWave !== 'none' ? 7 : 0} spacing={2}>
      <typo.H1 css={{textAlign: 'center', color: colors.periwinkle}}>{headline}</typo.H1>
      <typo.Intro css={{textAlign: 'center'}}>{children}</typo.Intro>
    </Col>
  </StripWithContent>
)

export default Intro
