import * as React from 'react'
import {FacebookShareButton, TwitterShareButton} from 'react-share'

import {Row, Col} from '../ui/layout'
import typo from '../ui/typo'

import {IconButton} from '../ui/buttons'
import facebookIcon from '../images/icon/icon__social--facebook.svg'
import twitterIcon from '../images/icon/icon__social--twitter.svg'

export const SocialSharingButtons = () => (
  <Row justify="center" spacing={1}>
    <FacebookShareButton url="https://www.superdays.fun">
      <IconButton icon={{src: facebookIcon, width: 30, height: 30}} />
    </FacebookShareButton>
    <TwitterShareButton url="https://www.superdays.fun" title="Make people happy with Superdays: ">
      <IconButton icon={{src: twitterIcon, width: 30, height: 30}} />
    </TwitterShareButton>
  </Row>
)

export const SocialLinks = () => (
  <Row justify="center" spacing={1}>
    <IconButton
      to="https://www.facebook.com/superdaysapp"
      target="__blank"
      icon={{src: facebookIcon, width: 30, height: 30}}
    />
    <IconButton
      to="https://twitter.com/superdaysapp"
      target="__blank"
      icon={{src: twitterIcon, width: 30, height: 30}}
    />
  </Row>
)

const SocialSharing = () => (
  <Col spacing={1}>
    <typo.BodyBold css={{textAlign: 'center'}}>
      Meanwhile, spread the love on your social networks 😊
    </typo.BodyBold>
    <SocialSharingButtons />
  </Col>
)

export default SocialSharing
