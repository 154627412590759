// @flow
import * as React from 'react'
import Helmet from 'react-helmet'
import 'typeface-nunito'
import 'typeface-karla'

import SocialSharingImage from '../images/sharing__social-large.png'
import Favicon from '../images/favicon.png'
import Footer from './Footer'
import {Col, Push} from '../ui/layout'
import Header from './Header'
import CookieBox from './CookieBox'
import {ScrollProvider} from './ScrollManager'
import {ToastProvider} from './Toaster'
import UrlHandler from './UrlHandler'
import CookieConsentProvider from './CookieConsentProvider'
import {getSegmentScriptTag} from './SegmentScriptTag'

const linkTags = [{rel: 'shortcut icon', type: 'image/png', href: Favicon}]

type DLProps = {
  children: React.Node,
  title: string,
  description: string,
}
const DefaultLayout = ({children, title, description}: DLProps) => {
  // According to: https://css-tricks.com/essential-meta-tags-social-media/
  const metaTags = [
    {name: 'description', content: description},
    {name: 'keywords', content: 'fun, superfun, calendar, friends, gift, social, adventure'},
    {property: 'og:title', content: title},
    {property: 'og:description', content: description},
    {property: 'og:url', content: 'https://www.superdays.fun'},
    {property: 'og:image', content: SocialSharingImage},
    {property: 'og:type', content: 'website'},
    {property: 'twitter:card', content: 'summary_large_image'},
    {property: 'twitter:image', content: SocialSharingImage},
    {property: 'fb:app_id', content: '1955748061303852'},
    {name: 'google-site-verification', content: 'tcTtWlFRrnj8TjsK8IZ3TWXAyLnzCtyQvt8oAL5-D7U'},
  ]
  return (
    <ToastProvider>
      <ScrollProvider>
        <CookieConsentProvider>
          {(hasAgreed, setAgreed) => (
            <React.Fragment>
              <Helmet title="Superdays" meta={metaTags} link={linkTags}>
                <html lang="en" />
                {hasAgreed && process.env.GATSBY_MIXPANEL_WRITE_KEY ? (
                  <script type="text/javascript">
                    {getSegmentScriptTag(process.env.GATSBY_MIXPANEL_WRITE_KEY)}
                  </script>
                ) : null}
              </Helmet>
              <Col minHeight="100vh">
                <Header />
                <Col paddingTop={7}>{children}</Col>
                <Push />
                <Footer />
              </Col>
              {hasAgreed ? null : <CookieBox onCloseClick={() => setAgreed(true)} />}
              <UrlHandler />
            </React.Fragment>
          )}
        </CookieConsentProvider>
      </ScrollProvider>
    </ToastProvider>
  )
}

export default DefaultLayout
