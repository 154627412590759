import * as React from 'react'

import BottomWaveYellow from '../images/bottom-wave-yellow.svg'
import BottomWaveGreen from '../images/bottom-wave-green.svg'
import BottomCurveImage from '../images/bottom-curve.svg'

const bottomImageStyle = {
  bottom: 0,
  position: 'absolute',
  zIndex: 1,
  width: '100%',
  // Sometimes a semitransparent white line shines through without margin.
  marginBottom: -1,
}

type BWProps = {
  variant: 'yellow' | 'green',
  style?: object,
}
export const BottomWave = ({variant = 'yellow', style}: BWProps) => (
  <img
    css={{...bottomImageStyle, ...style}}
    src={variant === 'yellow' ? BottomWaveYellow : BottomWaveGreen}
  />
)

export const BottomCurve = () => <img css={bottomImageStyle} src={BottomCurveImage} />
