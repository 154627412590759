import * as React from 'react'
import * as ReactDOM from 'react-dom'

export default class Portal extends React.Component {
  componentWillUnmount() {
    this.tearDown()
  }

  tearDown() {
    if (!this.portal) return
    const {body} = document
    if (!body) throw new Error('Need a document.body')
    body.removeChild(this.portal)
    this.portal = null
  }

  render() {
    const {children} = this.props
    const {body} = document
    if (!body) throw new Error('Need a document.body')
    if (children) {
      let portal = this.portal
      if (!portal) {
        portal = document.createElement('div')
        body.appendChild(portal)
        this.portal = portal
      }
      return ReactDOM.createPortal(React.Children.only(children), portal)
    } else {
      this.tearDown()
      return null
    }
  }
}
