// @flow
import React from 'react'

import colors from 'superdays-shared/colors'

import CookieIcon from '../images/icon-cookies.svg'
import {StripWithContent, Col} from '../ui/layout'
import typo from '../ui/typo'
import {FullButton} from '../ui/buttons'

const containerStyle = {
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: '#fff',
  zIndex: 999999999 + 1, // To get it over the chat message bubble from SmallChat.
  boxShadow: '0 2px 10px 0 rgba(0,0,0,0.18)',
}

type CBProps = {
  onCloseClick: () => void,
}
const CookieBox = ({onCloseClick}: CBProps) => (
  <div css={containerStyle}>
    <StripWithContent color={colors.white}>
      <Col paddingVertical={1} spacing={1} align="center">
        <img width="37" height="30" alt="Cookie" src={CookieIcon} css={{width: '3rem'}} />
        <typo.Small css={{textAlign: 'center'}}>
          <b>
            Mhhhhhhh{' '}
            <a
              css={{textDecoration: 'underline'}}
              href="https://www.iubenda.com/privacy-policy/80217704/cookie-policy"
              rel="noopener noreferrer"
              target="_blank"
            >
              cookie policy!
            </a>
          </b>{' '}
          This website uses cookies and by using it you agree it&#39;s ok.
        </typo.Small>
        <FullButton onClick={onCloseClick}>Alright</FullButton>
      </Col>
    </StripWithContent>
  </div>
)

export default CookieBox
