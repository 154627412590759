// @flow
// TODO: Remove duplicate color definitions.
const colors = {
  lightWhite: '#FCFFFE',
  backgroundWhite: '#FCFCFC',
  breakHeaderGray: '#F4F4F4',
  darkGray: '#9B9B9B',
  error: '#D0021B',
  errorBackground: '#9E2F3D',
  gray: '#C2C2C2',
  grayText: '#849192',
  lightGray: '#DCDCDC',
  lightText: '#FFFFFF',
  mintGreen: '#58D8B6',
  borderColor: '#ECECEC',
  periwinkle: '#6B99FF',
  roastedBlue: '#22438B',
  text: '#476163',
  warning: '#9a6d00',
  white: '#fff',
  yellow: '#FFE35B',
  danger: '#D0021B',
  dangerDark: '#9E2F3D',
  mintGreenHightlight: '#60e5c2',
  mintGreenFade15: 'rgba(88,216,182,0.15)',
  periwinkleHighlight: '#75a0ff',
  lightTextColor: 'rgba(71,97,99, 0.7)',
  superlightTextColor: '#C2C2C2',
  disabledTextColor: 'rgba(71,97,99, 0.35)',
  yellowGradient: 'linear-gradient(180deg, #FFE35B 0%, rgba(255, 227, 91, 0.2) 100%)',
  blueGradient:
    'linear-gradient(180deg, rgba(107, 153, 255, 0) 0%, rgba(107, 153, 255, 0.2) 50%, rgba(107, 153, 255, 0.5) 100%)',
  lightGreenGradient: 'linear-gradient(180deg, rgba(240, 248, 246, 0) 16.99%, #F0F8F6 99.76%)',
  greenGradient: 'linear-gradient(180deg, #58D8B6 0%, rgba(88, 216, 182, 0.2) 100%)',
}

export default colors
