// @flow
import * as React from 'react'
import Cookies from 'js-cookie'

type CCPProps = {
  children: (hasAgreed: boolean, setAgreed: (hasAgreed: boolean) => void) => React.Node,
}
type CCPState = {
  hasAgreed: boolean,
}
class CookieConsentProvider extends React.Component<CCPProps, CCPState> {
  state = {
    hasAgreed: !!Cookies.get('hasAgreedToCookies'),
  }

  handleSetAgreed = (hasAgreed: boolean) => {
    Cookies.set('hasAgreedToCookies', hasAgreed, {expires: 365})
    this.setState({hasAgreed})
  }

  render() {
    const {hasAgreed} = this.state
    return this.props.children(hasAgreed, this.handleSetAgreed)
  }
}

export default CookieConsentProvider
