// @flow
import * as React from 'react'
import colors from 'superdays-shared/colors'
import {Link as GatsbyLink} from 'gatsby'

export const fonts = {
  heading: {
    fontFamily: 'Nunito',
    fontWeight: 'bold',
  },
  intro: {
    fontFamily: 'Nunito',
  },
  body: {
    fontFamily: 'Karla',
  },
  bodyBold: {
    fontFamily: 'Karla',
    fontWeight: 'bold',
  },
}

const FONT_SIZE_OF_HTML_TAG_IN_PIXELS = 16

export const toRem = (fontSizeInPixels: number) =>
  `${fontSizeInPixels / FONT_SIZE_OF_HTML_TAG_IN_PIXELS}rem`

export const fontStyles = {
  h1: {
    ...fonts.heading,
    fontSize: toRem(36),
    // We want to use ratios for line height, as it is best practice to define the line-height
    // relative to the font-size. This is why we turn the design's absolute value into a relative
    // value here and below.
    lineHeight: 42 / 36,
  },
  h2: {
    ...fonts.heading,
    fontSize: toRem(28),
    lineHeight: 34 / 28,
  },
  h3: {
    ...fonts.heading,
    fontSize: toRem(21),
    lineHeight: 28 / 21,
  },
  body: {
    ...fonts.body,
    fontSize: toRem(16),
    lineHeight: 24 / 16,
  },
  bodyLarge: {
    ...fonts.body,
    fontSize: toRem(18),
    lineHeight: 24 / 18,
  },
  bodyBold: {
    ...fonts.bodyBold,
    fontSize: toRem(16),
    lineHeight: 24 / 16,
  },
  smallBody: {
    ...fonts.body,
    fontSize: toRem(12),
    lineHeight: 17 / 12,
  },
  smallBoldBody: {
    ...fonts.bodyBold,
    fontSize: toRem(12),
    lineHeight: 17 / 12,
  },
  link: (color: string = colors.mintGreen, hoverColor: string = colors.mintGreenHightlight) => ({
    color: color,
    transitionProperty: 'color',
    textDecoration: 'none',
    ':hover': {
      color: hoverColor,
    },
    cursor: 'pointer',
  }),
}

type Props = {
  as?: string | React.ComponentType<*>,
}
const styledText = (css: Object, defaultComp?: string | React.ComponentType<*>) => {
  const StyledTextComp = ({as, ...props}: Props) => {
    const Comp = as || defaultComp || 'div'
    return <Comp css={css} {...props} />
  }
  return StyledTextComp
}

type LinkProps = {
  href: string,
  children: React.Node,
}
export const ExternalOrInternalLink = ({href, children, ...rest}: LinkProps) =>
  href.indexOf('http') === 0 ? (
    <a href={href} {...rest}>
      {children}
    </a>
  ) : (
    <GatsbyLink to={href} {...rest}>
      {children}
    </GatsbyLink>
  )

export default {
  H1: styledText(fontStyles.h1, 'h1'),
  H2: styledText(fontStyles.h2, 'h2'),
  H3: styledText(fontStyles.h3, 'h3'),
  Intro: styledText({...fonts.intro, fontSize: '1.2rem', lineHeight: 1.5}),
  Logo: styledText({...fonts.heading, fontSize: toRem(16), lineHeight: 18 / 16}),
  Body: styledText(fontStyles.body),
  BodyLarge: styledText(fontStyles.bodyLarge),
  BodyBold: styledText(fontStyles.bodyBold),
  Small: styledText(fontStyles.smallBody),
  SmallBold: styledText(fontStyles.smallBoldBody),
  Link: styledText({...fontStyles.bodyBold, ...fontStyles.link()}),
  Label: styledText({
    ...fontStyles.smallBoldBody,
    color: colors.periwinkle,
  }),
}
