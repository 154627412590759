import * as React from 'react'
import Portal from 'superdays-shared/components/Portal'
import typo from '../ui/typo'
import colors from 'superdays-shared/colors'

/*
Usage:

- Wrap app in the `ToastProvider`

  <ToastProvider>
    <App/>
  </ToastProvider>

- Show Toast (would be so much nicer with Hooks!)

  <ShowToast>{show => (
    <button onClick={() => show({message: 'This is a message'})}>Show Toast!</button>
  )}</ShowToast>

*/

const pillContainerStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  pointerEvents: 'none',
}

const pillStyle = (state, offset) => ({
  backgroundColor: '#effffb',
  borderLeft: `0.3rem solid ${colors.mintGreen}`,
  borderRadius: '0.3rem',
  padding: '1rem 2rem',
  marginTop: '1rem',
  boxShadow: '0 0.2rem 1.5rem -0.5rem rgba(0, 0, 0, 0.36)',
  transitionProperty: 'opacity, transform',
  opacity: state === 'entered' ? 1 : 0,
  transform: `translateY(${offset * 0.2 + (state === 'entered' ? 0 : -0.5)}rem)`,
  pointerEvents: 'auto',
})

class AlertPill extends React.Component {
  state = {
    state: 'initial', // | 'entered' | 'leaving'
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({state: 'entered'})
    }, 20)
    this.checkIfOnFront()
  }

  componentDidUpdate() {
    this.checkIfOnFront()
  }

  checkIfOnFront() {
    const {message, isOnFront, onRemove} = this.props
    if (isOnFront) {
      this.timeoutId = setTimeout(() => {
        this.timeoutId = null
        this.setState({state: 'leaving'})
        this.leaveTimeoutId = setTimeout(() => onRemove(message.id), 250)
      }, message.durationInMs || 2000)
    } else if (this.timeoutId) {
      clearTimeout(this.timeoutId)
      this.timeoutId = null
    }
  }

  componentWillUnmount() {
    if (this.timeoutId) clearTimeout(this.timeoutId)
    if (this.leaveTimeoutId) clearTimeout(this.leaveTimeoutId)
  }

  render() {
    const {state} = this.state
    const {message, offset} = this.props
    return (
      <Portal>
        <div css={[pillContainerStyle, {zIndex: 3 + offset}]}>
          <div css={pillStyle(state, offset)}>
            <typo.H3 css={{color: colors.mintGreen}}>{message.message}</typo.H3>
          </div>
        </div>
      </Portal>
    )
  }
}

const ToastContext = React.createContext({
  messages: [],
  addMessage: () => {
    // eslint-disable-next-line no-console
    console.warn('You need to use a `ToastProvider` up the component tree!')
  },
})

let nextMsgId = 1

export class ToastProvider extends React.Component {
  state = {
    messages: [],
    addMessage: message => this.handleAddMessage(message),
  }

  componentWillUnmount() {
    this.isUnmounted = true
  }

  handleAddMessage = message => {
    const newMessage = {id: (nextMsgId += 1), ...message}
    this.setState(({messages}) => ({messages: [newMessage, ...messages]}))
  }

  removeMessage = msgId => {
    if (this.isUnmounted) return
    this.setState(({messages}) => ({messages: messages.filter(m => m.id !== msgId)}))
  }

  render() {
    const {messages} = this.state
    const {children} = this.props
    return (
      <ToastContext.Provider value={this.state}>
        <React.Fragment>
          {messages.map((message, idx) => (
            <AlertPill
              key={message.id}
              message={message}
              onRemove={this.removeMessage}
              offset={idx}
              isOnFront={idx === messages.length - 1}
            />
          ))}
          {children}
        </React.Fragment>
      </ToastContext.Provider>
    )
  }
}

export const ShowToast = ({children}) => (
  <ToastContext.Consumer>{ctx => children(ctx.addMessage)}</ToastContext.Consumer>
)
